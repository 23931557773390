import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import Configuration from "../helpers/configuration/Configuration"
import Protobuf from "../helpers/configuration/Protobuf"
import { base64Decode } from "../helpers/utils"

const UseDecodeCustomParameter = () => {
  const params = useParams()
  const [values, setValues] = useState(null)
  const [valid, setValid] = useState(false)
  const [loaded, setLoaded] = useState(false)

  // decode protobuf string in order to generate the values object
  useEffect(() => {
    if (!params.SCHEMA || !params.CUSTOM) {
      return
    }

    const schema = params.SCHEMA
    const string = base64Decode(params.CUSTOM)

    new Protobuf(schema).decode(string).then(params => {
      const configuration = new Configuration()
        .setParams(params)
        .setCustom(true)

      setValues(configuration.getValues())
      setValid(configuration.isValid())
      setLoaded(true)
    })
  }, [params])

  return { loaded, values, valid }
}

export default UseDecodeCustomParameter
