import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import ConfigurationContext from "../../../context/ConfigurationContext"
import { getApiEndpointUrl, getLambdaUrl } from "../../../helpers/general"
import useCustom from "../../../hooks/use-Custom"
import Modal from "../../Modal/Modal"
import RequestDataForm from "../../RequestDataForm/RequestDataForm"
import SubmissionResult from "../../RequestDataForm/SubmissionResult/SubmissionResult"
import Button from "../../buttons/Button"

import style from "./Actions.module.scss"

const Actions = () => {
  const { t, i18n } = useTranslation()
  const { isCustom } = useCustom()
  const context = useContext(ConfigurationContext)

  const { values, reset, showThanks, setShowThanks, showError, setShowError } =
    context
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }
  const handleCloseModal = () => {
    setModalIsOpen(false)
    setShowThanks(false)
    setShowError(false)
  }

  const handleDownloadPdf = async () => {
    const bodyValues = { ...values }
    delete bodyValues["personal_data"]
    delete bodyValues["all_walls_same"]

    const base64Configuration = window.btoa(JSON.stringify(bodyValues))

    const params = {
      lang: i18n.language,
      configuration: base64Configuration,
    }

    const queryStr = new URLSearchParams(params).toString()

    const siteUrl = getLambdaUrl()
    const apiEndpointPdf = getApiEndpointUrl("pdf")

    const url = `${siteUrl}${apiEndpointPdf}?${queryStr}`

    window.open(url, "_blank")
  }

  const renderModalContent = () => {
    if (showError) {
      return <SubmissionResult error={true} />
    }

    if (showThanks) {
      return <SubmissionResult />
    }

    return <RequestDataForm />
  }

  if (isCustom) {
    return ""
  }

  return (
    <div className={style.actions}>
      <div className={style.left}>
        <button
          className={style.reset}
          type="button"
          onClick={reset ? reset : null}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.80658 1.49556L3.149 0L2.64295 1.576L5.06098 2.35244C2.23753 4.50013 0.413086 7.92491 0.413086 11.7786C0.413086 18.2654 5.58762 23.5478 11.9999 23.5478C18.4122 23.5478 23.5867 18.2654 23.5867 11.7786C23.5867 7.26831 21.0876 3.34556 17.4127 1.37008L16.629 2.82803C19.7794 4.5216 21.9315 7.89149 21.9315 11.7786C21.9315 17.3775 17.4719 21.8925 11.9999 21.8925C6.52791 21.8925 2.06835 17.3775 2.06835 11.7786C2.06835 8.4167 3.67811 5.44169 6.15132 3.60347V6.58141H7.80658V1.49556ZM12.8275 0.374211H11.1723V12.7887H12.8275V0.374211Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
      <span className={style.separator}>|</span>
      <div className={style.right}>
        <div className={style.contentBtn}>
          <Button type="button" onClick={handleDownloadPdf}>
            {t("Download PDF")}
          </Button>
        </div>
        <div className={style.contentBtn}>
          <Button primary={true} type="button" onClick={toggleModal}>
            {t("Request a quote")}
          </Button>
          <Modal
            large={true}
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
          >
            {renderModalContent()}
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Actions
