import { useContext } from "react"

import PropTypes from "prop-types"

import ConfigurationContext from "../../../context/ConfigurationContext"
import useCustom from "../../../hooks/use-Custom"

import styles from "./AreaListItem.module.scss"

const AreaListItem = ({ areaId, icon, name, active }) => {
  const context = useContext(ConfigurationContext)
  const { isCustom } = useCustom()
  const { goToArea } = context

  const handleSelectArea = () => {
    if (!active) {
      return null
    }

    goToArea(areaId)
  }

  return (
    <li
      className={[styles.listItem, !active ? styles.disabled : " "].join(" ")}
    >
      <button
        type="button"
        onClick={handleSelectArea}
        className={styles.buttonItem}
        disabled={isCustom ? true : false}
      >
        {icon && <span className={styles.iconList}>{icon}</span>}
        {name && <h3 className={styles.h3}>{name}</h3>}

        <span className={styles.contentSvg}>
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.22795 5.95836L0.436301 9.75001L2.34315 11.6569L8.00001 6.00001L6.09315 4.09315L6.09314 4.09317L2.34313 0.343154L0.477932 2.20835L4.22795 5.95836Z"
              fill="black"
            />
          </svg>
        </span>
      </button>
    </li>
  )
}

export default AreaListItem

AreaListItem.propTypes = {
  areaId: PropTypes.string.isRequired,
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
}

AreaListItem.defaultProps = {
  areaId: "",
  icon: null,
  name: "",
  active: true,
}
