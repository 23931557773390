import React from "react"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"
import { Link } from "react-router-dom"

import PropTypes from "prop-types"

import logo from "../../assets/logo.svg"
import logoMobil from "../../assets/logo_mobil.svg"
import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher"
import useCustom from "../../hooks/use-Custom"
import useScroll from "../../hooks/use-Scroll"
import useReloadWarning from "../../hooks/use-reload-warning"

import styles from "./Header.module.scss"

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ query: "(max-width:992px)" })
  return isMobile ? children : null
}
const PC = ({ children }) => {
  const isPc = useMediaQuery({ query: "(min-width: 993px)" })
  return isPc ? children : null
}

const Header = ({ data, inConfiguration, children }) => {
  const { i18n } = useTranslation()
  const { isCustom } = useCustom()
  const y = useScroll()

  const { handleReloadWarning } = useReloadWarning(inConfiguration)

  const windowWidth = window.innerWidth

  return (
    <header
      className={[
        styles.header,
        inConfiguration === true ? styles.inConfiguration : "",
        y > 2 && windowWidth < 993 ? styles.isScrolling : " ",
      ].join(" ")}
    >
      <Mobile>
        {!isCustom && (
          <div className={styles.contentMobildioma}>
            <div className={styles.idiomasMobil}>
              <LanguageSwitcher />
            </div>
          </div>
        )}
      </Mobile>{" "}
      <div className={styles.wrapper}>
        <div className={styles.contentHeader}>
          <div className={styles.logo}>
            {isCustom ? (
            <div>
              <Mobile>
                <img src={logoMobil} alt="Orona" />
                {!inConfiguration && (
                  <h1 className={styles.h1}>{data.titulo}</h1>
                )}
              </Mobile>
              <PC>
                <img src={logo} alt="Orona" />
              </PC>
            </div>
            ) : (
            <Link
              to={`/${i18n.language}`}
              onClick={event => handleReloadWarning(event)}
            >
              <Mobile>
                <img src={logoMobil} alt="Orona" />
                {!inConfiguration && (
                  <h1 className={styles.h1}>{data.titulo}</h1>
                )}
              </Mobile>
              <PC>
                <img src={logo} alt="Orona" />
              </PC>
            </Link>
            )}
          </div>
          <PC>
            <div className={styles.titular}>
              <h1 className={styles.h1}>{data.titulo}</h1>
            </div>
            {!isCustom && (
              <div className={styles.contentLang}>
                <LanguageSwitcher />
              </div>
            )}
          </PC>
        </div>
        {children}
      </div>
    </header>
  )
}

export default Header

Header.propTypes = {
  inConfiguration: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
  }),
  children: PropTypes.node,
}

Header.defaultProps = {
  inConfiguration: false,
  children: null,
}
