import ConfigureCustom from "./pages/Configure/ConfigureCustom"
import ConfigureStandard from "./pages/Configure/ConfigureStandard"
import CookiesPolicy from "./pages/CookiesPolicy"
import Home from "./pages/Home"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import Summary from "./pages/Summary"
import TermsOfUse from "./pages/TermsOfUse"

const routes = [
  {
    id: "home",
    path: "",
    index: true,
    component: <Home />,
    layout: "general",
  },
  {
    id: "configure",
    path: ":CCAB_MODELO/:CCAB_EMBARQUE/configure/:CCAB_AMBIENTE",
    index: false,
    component: <ConfigureStandard />,
    layout: "configurator",
  },
  {
    id: "custom",
    path: "custom/:SCHEMA/:CUSTOM",
    index: false,
    component: <ConfigureCustom />,
    layout: "configurator",
  },
  {
    id: "summary",
    path: ":CCAB_MODELO/:CCAB_EMBARQUE/summary",
    index: false,
    component: <Summary />,
    layout: "general",
  },
  {
    id: "terms-of-use",
    path: "terms-of-use",
    index: false,
    component: <TermsOfUse />,
    layout: "general",
  },
  {
    id: "privacy-policy",
    path: "privacy-policy",
    index: false,
    component: <PrivacyPolicy />,
    layout: "general",
  },
  {
    id: "cookies-policy",
    path: "cookies-policy",
    index: false,
    component: <CookiesPolicy />,
    layout: "general",
  },
]

export default routes
