import React from "react"
import { Outlet } from "react-router-dom"

import ConfiguratorHeader from "../../components/Configuration/ConfiguratorHeader/ConfiguratorHeader"
import { getSiteTitle } from "../../helpers/general"
import Header from "../Header/Header"

import useCustom from "../../hooks/use-Custom"
import styles from "./ConfiguratorLayout.module.scss"

const ConfiguratorLayout = () => {
  const {isCustom} = useCustom()

  return (
    <div className={styles.content}>
      {/* Main header */}
      <Header
        data={{
          titulo: getSiteTitle(isCustom),
        }}
        inConfiguration={true}
      >
        <ConfiguratorHeader />
      </Header>

      {/* Main content */}
      <main className={styles.main}>
        <Outlet />
      </main>
    </div>
  )
}

export default ConfiguratorLayout
