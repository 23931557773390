import { useContext, useEffect, useState } from "react"
import { useBeforeunload } from "react-beforeunload"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"

import { Form, Formik } from "formik"

import Actions from "../../components/Configuration/Actions/Actions"
import Area from "../../components/Configuration/Area/Area"
import AreaList from "../../components/Configuration/AreaList/AreaList"
import View from "../../components/Configuration/Preview/View/View"
import ViewNavigation from "../../components/Configuration/Preview/ViewNavigation/ViewNavigation"
import ViewSwitcher from "../../components/Configuration/Preview/ViewSwitcher/ViewSwitcher"
import Error404 from "../../components/Error404/Error404"
import Error404Wrapper from "../../components/Error404/Error404Wrapper/Error404Wrapper"
import SEO from "../../components/SEO/SEO"
import { AppContext } from "../../context/AppContext"
import { CasUserContext } from "../../context/CasUserContext"
import ConfigurationContext from "../../context/ConfigurationContext"
import AreaClass from "../../helpers/configuration/area"
import {
  findArea,
  getConfiguratorAreas,
  getNextArea,
  getPrevArea,
} from "../../helpers/configuration/areas"
import initialPersonalData from "../../helpers/configuration/initla-personal-data"
import validate from "../../helpers/configuration/validation/validate"
import ViewClass from "../../helpers/configuration/view"
import { findView, getDefaultView } from "../../helpers/configuration/views"
import {
  getApiEndpointUrl,
  getLambdaUrl,
  str2bool,
  stripHtml,
} from "../../helpers/general"
import useCustom from "../../hooks/use-Custom"
import useScroll from "../../hooks/use-Scroll"

import styles from "./Configure.module.scss"

const Configure = props => {
  const { t, i18n } = useTranslation()
  const isMobile = useMediaQuery({ query: "(max-width:1023px)" })
  const appContext = useContext(AppContext)
  const { store } = appContext
  const { isCustom } = useCustom()
  const [currentArea, setCurrentArea] = useState(null)
  const [currentView, setCurrentView] = useState(getDefaultView(store.views))
  const mobileCustom = isCustom && isMobile

  const [showThanks, setShowThanks] = useState(false)
  const [showError, setShowError] = useState(false)

  const casUserContext = useContext(CasUserContext)
  const isLoggedIn = casUserContext.user !== null

  useBeforeunload(event => {
    if (isLoggedIn) {
      event.preventDefault()
    }
  })

  const vh = window.innerHeight
  const y = useScroll()
  document.documentElement.style.setProperty("--vh", `${vh}px`)
  document.documentElement.style.setProperty("--scroll", `${y}px`)

  // Scroll to top every time we change the area or the view
  useEffect(() => {
    if (window.scrollY > 2 && window.innerWidth < 993) {
      window.scrollTo(0, 3)
    }
  }, [currentArea, currentView])

  const [isLoadingLayers, setIsLoadingLayers] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoadingLayers(false)
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  // fist things first, check if initialValues are valid
  if (!props.valid) {
    return (
      <Error404Wrapper>
        <Error404 />
      </Error404Wrapper>
    )
  }

  const goToMenu = () => {
    setCurrentArea(null)

    const defaultView = getDefaultView(store.views)
    setCurrentView(defaultView)
  }

  const goToArea = areaId => {
    const areaData = findArea(store.categories, areaId)
    const areaObj = AreaClass(areaId, areaData)
    const areaDefaultView = areaObj.getDefaultView()

    setCurrentArea(areaId)
    switchView(areaDefaultView)
  }

  const switchView = viewId => {
    const viewData = findView(store.views, viewId)
    const viewObj = ViewClass(viewId, viewData)
    const defaultView = getDefaultView(store.views)

    const newViewId = viewObj.isActive() ? viewId : defaultView

    setCurrentView(newViewId)
  }

  const resetConfiguration = cb => {
    // Callback to Formik function that resets configuration values with the initial values
    cb()

    goToMenu()
  }

  const formatSubmitValues = values => {
    const submitValues = { ...values }

    // turn file_send field value from string ("yes"/"no") to boolean value
    submitValues.personal_data.file_send = str2bool(
      values.personal_data.file_send
    )

    return submitValues
  }

  const resetPersonalData = (values, options) => {
    // Callback to Formik function that sets the form values
    const { setValues } = options

    // Reset only personal data
    const newValues = { ...values, ...initialPersonalData }

    setValues(newValues, false)
  }

  const displayAreaList = isCustom && isMobile ? false : currentArea === null

  const configuratorAreas = getConfiguratorAreas(store.categories)
  const views = store.views

  const contextValues = {
    currentArea,
    prevArea: getPrevArea(configuratorAreas, currentArea),
    nextArea: getNextArea(configuratorAreas, currentArea),
    goToMenu,
    goToArea,
    reset: null,
    allAreas: configuratorAreas,
    currentView,
    switchView,
    views,
    values: null,
    showThanks,
    setShowThanks,
    showError,
    setShowError,
  }

  const PC = ({ children }) => {
    const isMobile = useMediaQuery({ query: "(min-width:1024px)" })
    return isMobile ? children : null
  }

  const handleSubmit = async (values, actions) => {
    const submitValues = formatSubmitValues(values)

    const data = {
      lang: i18n.language,
      ...submitValues,
    }

    const siteUrl = getLambdaUrl()
    const apiEndpointQuote = getApiEndpointUrl("quote")
    const url = `${siteUrl}${apiEndpointQuote}`

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    actions.setSubmitting(false)

    if (!response.ok) {
      setShowError(true)
      return
    }

    setShowThanks(true)
    resetPersonalData(values, { setValues: actions.setValues })
  }

  const metaTitle = stripHtml(t("Car design tool"))
  const metaDescription = stripHtml(t("bienvenido.description"))

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <Formik
        initialValues={{ ...props.initialValues, ...initialPersonalData }}
        validate={validate}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {formikProps => {
          return (
            <Form className={styles.formContent}>
              <ConfigurationContext.Provider
                value={{
                  ...contextValues,
                  values: formikProps.values,
                  reset: () => {
                    resetConfiguration(formikProps.resetForm)
                  },
                }}
              >
                <div className={styles.configuration}>
                  <div
                    className={[
                      styles.preview,
                      y > 2 ? styles.scrolling : " ",
                      isCustom && isMobile ? styles.customView : "",
                    ].join(" ")}
                  >
                    <View isLoading={isLoadingLayers} />

                    <ViewNavigation />
                    <PC>
                      {" "}
                      <ViewSwitcher />
                    </PC>
                  </div>

                  {!mobileCustom && (
                    <div className={styles.menu}>
                      <div className={styles.content}>
                        <div
                          className={[
                            styles.menuTopArea,
                            y > 2 && currentArea ? styles.scrolling : " ",
                          ].join(" ")}
                        >
                          {displayAreaList && (
                            <AreaList areas={configuratorAreas} />
                          )}

                          {currentArea && <Area id={currentArea} />}
                        </div>

                        <Actions />
                      </div>
                    </div>
                  )}
                </div>
              </ConfigurationContext.Provider>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default Configure
