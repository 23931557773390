import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useMediaQuery } from "react-responsive"
import { NavLink, useLocation, useParams } from "react-router-dom"

import { AppContext } from "../../../context/AppContext"
import VariableClass from "../../../helpers-shared/configuration/variable"
import AreaClass from "../../../helpers/configuration/area"
import {
  findArea,
  getHomeStepsAreas,
} from "../../../helpers/configuration/areas"
import { getFistStepsValues } from "../../../helpers/configuration/general"
import { getCmsAssetsUrl } from "../../../helpers/general"
import useCustom from "../../../hooks/use-Custom"
import useDecodeCustomParameter from "../../../hooks/use-decode-custom-parameter"
import useReloadWarning from "../../../hooks/use-reload-warning"
import Svg from "../../Svg/Svg"

import style from "./ConfiguratorHeader.module.scss"

const assetsUrl = getCmsAssetsUrl()

const ConfiguratorHeader = () => {
  const { values: customValues } = useDecodeCustomParameter()

  const params = useParams()
  const { state } = useLocation()

  const { i18n, t } = useTranslation()

  const appContext = useContext(AppContext)

  const { store } = appContext

  const { handleReloadWarning } = useReloadWarning(true)

  const firstStepsValues = {
    ...getFistStepsValues(state, params),
    ...customValues,
  }
  const { isCustom } = useCustom()

  const homeSteps = getHomeStepsAreas(store.categories)

  const selectedModel = getSelectedOption({
    allAreas: homeSteps,
    value: firstStepsValues.CCAB_MODELO,
    areaId: "MODELO",
    langcode: i18n.language,
  })

  const selectedBoarding = getSelectedOption({
    allAreas: homeSteps,
    value: firstStepsValues.CCAB_EMBARQUE,
    areaId: "EMBARQUE",
    langcode: i18n.language,
  })
  const isMobile = useMediaQuery({ query: "(min-width: 993px)" })
  const Mobile = ({ children }) => {
    return isMobile ? children : null
  }

  return (
    <div className={style.contentHeaderSelection}>
      <div className={style.parameterSelection}>
        {selectedModel?.shortText && (
          <p className={style.text}>{selectedModel.shortText}</p>
        )}
        {selectedBoarding?.image && (
          <p className={style.imgContent}>
            <Svg src={selectedBoarding.image} />
          </p>
        )}
      </div>
      {!isCustom && (
        <div className={style.back}>
          <NavLink
            title={!isMobile ? t("New design") : ""}
            to={`/${i18n.language}`}
            state={{ canShowWelcome: false }}
            onClick={event => handleReloadWarning(event)}
          >
            <Mobile>
              <span className={style.textlink}> {t("New design")}</span>
            </Mobile>

            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.22808 6.30149L0.436437 10.0931L2.34329 12L8.00014 6.34313L6.09329 4.43628L6.09328 4.43629L2.34326 0.686279L0.478068 2.55147L4.22808 6.30149Z"
                fill="black"
              />
            </svg>
          </NavLink>
        </div>
      )}
    </div>
  )
}

export default ConfiguratorHeader

const getSelectedOption = ({ allAreas, value, areaId, langcode }) => {
  if (!allAreas || allAreas.length <= 0 || !value || !areaId || !langcode) {
    return ""
  }

  const area = findArea(allAreas, areaId)
  const areaObj = AreaClass(area.ID, area)
  const variable = areaObj.getFirstVariable()

  if (!variable) {
    return ""
  }

  const [variableId, variableData] = variable

  const variableObj = VariableClass(variableId, variableData)

  const variablePreparedOptions = variableObj.getPreparedOptions(langcode, {
    assetsUrl,
  })

  if (!variablePreparedOptions || variablePreparedOptions.length <= 0) {
    return ""
  }

  const selectedOption = variablePreparedOptions.find(o => o.value === value)

  if (!selectedOption) {
    return ""
  }

  return selectedOption
}
